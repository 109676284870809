import React from "react"
import PropTypes from "prop-types"

const ProductHunt = ({ color }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1025.2 240"
      width="2500"
      height="585"
    >
      <g id="Page-1">
        <g id="product-hunt-logo-horizontal-orange">
          <path
            id="Fill-1"
            fill="#da552f"
            d="M240 120c0 66.3-53.7 120-120 120S0 186.3 0 120 53.7 0 120 0s120 53.7 120 120"
          />
          <path
            id="Fill-3"
            fill="#fff"
            d="M136 120h-34V84h34c9.9 0 18 8.1 18 18s-8.1 18-18 18m0-60H78v120h24v-36h34c23.2 0 42-18.8 42-42s-18.8-42-42-42"
          />
          <path
            id="Fill-5"
            fill="#da552f"
            d="M366.1 105.3c0-6.6-5.1-10.6-11.7-10.6h-17.7v21.1h17.7c6.6 0 11.7-4 11.7-10.5zM320 160V80h36.8c17.1 0 26.4 11.5 26.4 25.3 0 13.7-9.4 25.2-26.4 25.2h-20V160H320z"
          />
          <path
            id="Fill-7"
            fill="#da552f"
            d="M393.3 160v-58.3h15v7.7c4.1-4.9 11.1-9.1 18.2-9.1v14.6c-1.1-.2-2.4-.3-4.1-.3-5 0-11.6 2.8-14 6.5v39h-15.1z"
          />
          <path
            id="Fill-9"
            fill="#da552f"
            d="M476.8 130.1c0-8.8-5.2-16.5-14.6-16.5-9.3 0-14.5 7.7-14.5 16.5 0 9 5.2 16.6 14.5 16.6 9.4 0 14.6-7.7 14.6-16.6m-44.7 0c0-16.1 11.3-29.8 30-29.8 18.9 0 30.2 13.7 30.2 29.8S481 160 462.1 160c-18.7 0-30-13.8-30-29.9"
          />
          <path
            id="Fill-11"
            fill="#da552f"
            d="M542.7 140.2v-20.1c-2.5-3.8-7.9-6.5-12.8-6.5-8.6 0-14.5 6.7-14.5 16.5 0 9.9 5.9 16.6 14.5 16.6 4.9 0 10.3-2.7 12.8-6.5zm0 19.8v-8.7c-4.5 5.7-10.6 8.7-17.6 8.7-14.3 0-25.2-10.8-25.2-29.9 0-18.5 10.7-29.8 25.2-29.8 6.7 0 13.1 2.8 17.6 8.7V80h15.1v80h-15.1z"
          />
          <path
            id="Fill-13"
            fill="#da552f"
            d="M611 160v-8.6c-3.9 4.2-10.7 8.6-20 8.6-12.5 0-18.4-6.8-18.4-17.9v-40.4h15v34.5c0 7.9 4.1 10.5 10.5 10.5 5.8 0 10.4-3.2 13-6.5v-38.5h15V160H611z"
          />
          <path
            id="Fill-15"
            fill="#da552f"
            d="M637.2 130.1c0-17.4 12.7-29.8 30.2-29.8 11.7 0 18.7 5.1 22.5 10.3l-9.8 9.2c-2.7-4-6.8-6.1-12-6.1-9.1 0-15.4 6.6-15.4 16.5s6.4 16.6 15.4 16.6c5.2 0 9.3-2.4 12-6.2l9.8 9.2c-3.8 5.2-10.8 10.4-22.5 10.4-17.5-.2-30.2-12.6-30.2-30.1"
          />
          <path
            id="Fill-17"
            fill="#da552f"
            d="M702.8 144.3v-29.6h-9.4v-13.1h9.4V86.1h15v15.6h11.5v13.1h-11.5v25.6c0 3.7 1.9 6.4 5.2 6.4 2.2 0 4.4-.8 5.2-1.8l3.2 11.4c-2.2 2-6.2 3.7-12.5 3.7-10.6-.1-16.1-5.5-16.1-15.8"
          />
          <path
            id="Fill-19"
            fill="#da552f"
            d="M825.1 160v-34.5H788V160h-16.7V80H788v30.9h37.1V80H842v80h-16.9z"
          />
          <path
            id="Fill-21"
            fill="#da552f"
            d="M895.5 160v-8.6c-3.9 4.2-10.7 8.6-20 8.6-12.5 0-18.4-6.8-18.4-17.9v-40.4h15v34.5c0 7.9 4.1 10.5 10.5 10.5 5.8 0 10.4-3.2 13-6.5v-38.5h15V160h-15.1z"
          />
          <path
            id="Fill-23"
            fill="#da552f"
            d="M963.7 160v-35.8c0-7.9-4.1-10.6-10.5-10.6-5.9 0-10.4 3.3-13 6.6V160h-15v-58.3h15v7.3c3.7-4.2 10.7-8.7 19.9-8.7 12.6 0 18.6 7.1 18.6 18.1V160h-15z"
          />
          <path
            id="Fill-25"
            fill="#da552f"
            d="M996.7 144.3v-29.6h-9.4v-13.1h9.4V86.1h15v15.6h11.5v13.1h-11.5v25.6c0 3.7 1.9 6.4 5.2 6.4 2.2 0 4.4-.8 5.2-1.8l3.2 11.4c-2.2 2-6.2 3.7-12.5 3.7-10.6-.1-16.1-5.5-16.1-15.8"
          />
        </g>
      </g>
    </svg>
  )
}

ProductHunt.propTypes = {
  color: PropTypes.string,
}

ProductHunt.defaultProps = {
  color: "#000000",
}

export default ProductHunt
